@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("/fonts/Avenir-Light.otf") format("opentype");
}

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir"), url("/fonts/Avenir-Roman.otf") format("opentype");
}
@font-face {
  font-family: "Avenir-Medium";
  font-weight: 500;
  src: local("Avenir"), url("/fonts/Avenir-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  src: local("Avenir"), url("/fonts/Avenir-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "Avenir-Book";
  font-weight: 900;
  src: local("Avenir"), url("/fonts/Avenir-Book.otf") format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"), url("/fonts/Roboto-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Manrope";
  src: url("/fonts/Manrope-VariableFont_wght.ttf") format("truetype-variations");
  font-weight: 1 999;
  font-stretch: 0% 100%;
  font-optical-sizing: 0 100;
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("/fonts/ProximaNova-Light.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("/fonts/ProximaNova-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("/fonts/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("/fonts/ProximaNova-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("/fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  src: url("/fonts/Lora-Medium.ttf") format("truetype");
  font-stretch: 0%;
}

@font-face {
  font-family: "WorkSans";
  src: url("/fonts/WorkSans-VariableFont_wght.ttf") format("truetype");
  font-weight: 1 999;
  font-stretch: 0% 100%;
  font-optical-sizing: 0 100;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: url("/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik-Regular";
  src: local("Graphik-400-Regular"),
    url("/fonts/Graphik-400-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Graphik-Medium";
  src: local("Graphik-500-Medium"),
    url("/fonts/Graphik-500-Medium.otf") format("opentype");
}

$font-family1: "Roboto Slab";
$font-family2: "Avenir";
$font-family3: "Avenir-Roman";
$font-family4: "Avenir-Medium";
$font-family5: "Roboto";
$font-family6: "Manrope";
$desktop-heading-size: 40px;
$mobile-heading-size: 1.55em;

body {
  font-family: $font-family2;
  overflow-x: hidden;
  box-sizing: border-box;
}
.App {
  text-align: center;
}
.threeDImage {
  top: 0;
}
.logo-img {
  margin-left: 20px;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family1;
}

.show-desktop {
  display: block;
  @media (max-width: 889px) {
    display: none !important;
  }
}
.show-mobile {
  display: block;
  @media (min-width: 990px) {
    display: none !important;
  }
}
.section-2 .subheading,
.section-six .header-text,
.section-4,
.section-3 .subheading,
.section-six .header-text {
  font-family: $font-family3;
  font-size: 20px;
  color: #737373;
}
.section-six .text-dark {
  font-family: $font-family1, serif;
}
.slider-text {
  text-shadow: 0 2px 25px rgba(0, 0, 0, 0.5);
  font-family: $font-family1, serif;
  // font-size: 48px;
  font-size: 1.5em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  .first-call,
  .second-call {
    display: block;
    margin: auto;
  }
  .second-call {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 900;
  }
  .text-border-brake {
    border: 3px solid white;
    margin: 30px auto;
    display: block;
  }
}
.heading {
  font-family: $font-family1, serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #25262b;
}
.section-subpar {
  font-family: $font-family3;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #737373;
}

.section-six {
  background-image: url("/landingPage/whitegradient.png");
  margin-bottom: 20px;
}
.nutshell-bg {
  background-image: url("/landingPage/nutshell_bg.svg");
  .plain-bg {
    @media (min-width: 990px) {
      height: 270px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      height: unset;
    }
  }
  .container {
    padding: 175px 70px;
    position: relative;
  }
  .heading {
    margin-bottom: 20px;
  }
  .nutshell-p {
    font-family: $font-family3;
    font-size: 1.25em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #737373;
  }

  .chair-sec {
    height: 215px;

    .nutshell-chair {
      top: -35%;
      position: relative;

      right: 0;
      max-width: 340px;
      display: block;
      min-width: 210px;
      margin: auto;
      @media (min-width: 992px) {
        position: relative;
        right: 22%;
        height: 330px;
        top: -45%;
      }
      @media (min-width: 700px) and (max-width: 900px) {
        width: 56%;
        right: 0;
        max-width: 400px;
        height: unset;
        display: block;
        min-width: 210px;
        margin: auto;
      }
      @media (max-width: 690px) {
        right: 0;
        max-width: 400px;
        height: unset;
        display: block;
        min-width: 210px;
        margin: auto;
        width: 72%;
      }
    }
  }
}
.plain-bg {
  background-color: rgb(255, 255, 255);
}

.footer-col-span {
  column-span: 3;
}
.footer-container {
  padding: 50px 0px;
}
.footer-rect {
  min-width: 280px;
  position: relative;
  border-radius: 4px;
  border: solid 1px #e9e9e9;
  padding: 10px 0;
  background-color: #ffffff;
  min-height: 98px;
  .footer-img {
    width: 70px;
    height: 70px;
    opacity: 0.1;
    left: 27px;

    top: 3%;
    position: absolute;
    border-radius: 50%;
    &.blue {
      background-color: #276dd7;
    }
    &.green {
      background-color: rgba(0, 255, 183, 1);
    }
    &.red {
      background-color: rgba(255, 180, 0, 1);
    }
  }

  img {
    position: absolute;
    left: 38px;
    height: 65px;
    top: 4px;
  }
  .mid-center {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 15%;
  }
  .block-text {
    font-family: "Avenir-Medium";
    font-weight: 500;
    height: 22px;
    font-size: 16px;
    color: #333333;
    position: absolute;
    left: 120px;
    top: 25%;
  }
}
.model-viewer-class {
  // height: 80vh;
  height: 54vh;
  width: 100%;
  @media (max-width: 990px) {
    // height: 65vh;
    height: 40vh;
  }
  &.header {
    height: 60vh;
  }
}
.footer-line {
  background-color: #333333;
  @media (max-width: 991px) {
    text-align: center;
    padding: 40px 6.5%;
  }
  @media (min-width: 992px) {
    padding: 80px 10%;
  }
  .socialIcons {
    .iconHolder {
      text-align: center;
      display: flex;
      width: 180px;
      @media (max-width: 991px) {
        margin: auto;
        margin-bottom: 20px;
      }
      img {
        flex: auto;
      }
    }
  }
}

.nutshell-bg {
  .heading {
    @media (max-width: 990px) {
      font-size: 1.55em;
    }
  }
}
.scene-creator {
  .subheading {
    padding: 40px 0;
    @media (max-width: 990px) {
      font-size: 1.25em;
      text-align: center;
    }
  }
  .heading {
    @media (max-width: 990px) {
      font-size: 32px;
    }
  }
  .heading,
  .subheading {
    @media (max-width: 990px) {
      width: 100%;
      max-width: none;
      text-align: center;
    }
  }
  .cocoen img {
    max-height: 700px;
  }
}
.section-3 .txt {
  top: 30%;
}
.section-4 .txt {
  top: 10%;
  .txt {
    min-height: 400px;
  }
}
.section-4,
.section-3 {
  .heading {
    margin-top: 30px;
  }
  .txt {
    position: relative;
    @media (max-width: 990px) {
      position: unset;
    }
  }
  .col-lg-7 {
    @media (min-width: 992px) {
      padding-right: 0;
    }
  }
}

.featured-in-container {
  // background: white;
  background: #f7f4ee;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 990px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.featured-in {
  margin: 0px 10%;
  @media (max-width: 990px) {
    margin: 0px 6.5%;
  }
}

.description-container {
  background: #f7f4ee;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (max-width: 990px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.description {
  margin: 0px 18%;
  @media (max-width: 990px) {
    margin: 0px 6.5%;
  }
}

.trusted-by-brands-container {
  background: white;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 990px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.trusted-by-brands {
  margin: 0 10%;
  @media (max-width: 990px) {
    margin: 0 6.5%;
  }
}

.client-testimony-container {
  background: white;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 990px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.client-testimony {
  margin: 0 5%;
  @media (max-width: 990px) {
    margin: 0 6.5%;
  }
}

.holistic-sol-container {
  background: #f7f4ee;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 991px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.holistic-sol {
  padding: 0 10%;
  @media (max-width: 991px) {
    padding: 0 6.5%;
  }
}

.m-r-10 {
  margin-right: 0;
  padding-right: 0;
  @media (max-width: 990px) {
    margin-right: 0;
    padding-right: 0;
  }
}

.our-product-offering-container {
  background: white;
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;

  @media (max-width: 990px) {
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden;
  }
}

.immersive-storefronts {
  margin: 0px 18%;
  padding: 0px;
  @media (max-width: 990px) {
    margin: 0px 6.5%;
    padding: 0 6.5%;
    margin: auto;
  }
}

.our-product-offering {
  margin: 0px 10%;
  @media (max-width: 990px) {
    margin: 0px 6.5%;
    padding: 0 6.5%;
    margin: auto;
  }
}

.how-it-works-container {
  background: #f7f4ee;
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 990px) {
    background: #faf8f5;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.parent-video {
  width: calc(100vw - 20vw);
  height: calc(100vh - 20vh);
}
.mobile-tutorial-video {
  width: calc(100vw - 20vw);
  height: calc(100vh - 50vh);
}
.how-it-works {
  margin: 40px 0;
  .scene-video {
    // video{
    //   max-width: 100%;
    //   height: auto;
    // }
    iframe {
      // max-width: 100%;
      // height: auto;
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: 990px) {
    margin: 0px 0;
  }
}

.section-2,
.section-4,
.section-3 {
  margin: 200px 0;
  @media (max-width: 990px) {
    margin: 100px 0;
  }

  h5,
  h3,
  h4 {
    text-align: initial;
    @media (max-width: 990px) {
      text-align: center;
    }
  }
  .asset-img {
    width: 100%;
  }
  .asset-component-buttons {
    text-align: initial;
    & > span {
      display: flex;
      width: 215px;
      @media (max-width: 990px) {
        margin: auto;
      }
    }
    .asset-selectors {
      height: 32px;
      width: 32px;
      cursor: pointer;
      flex: auto;
      opacity: 0.2;
      &.selected {
        opacity: 1;
        img {
          border: 1px solid red;
        }
      }
    }
  }
  .subheading {
    padding: 40px 0;
    @media (max-width: 990px) {
      font-size: 20px;
      text-align: center;
    }
  }
  .heading {
    @media (max-width: 990px) {
      font-size: 1.85em;
      padding-top: 25px;
    }
  }
  .heading,
  .subheading {
    text-align: initial;
    @media (max-width: 990px) {
      width: 100%;
      max-width: none;
      text-align: center;
    }
  }

  .text-border-brake {
    border: 2px solid;
    width: 110px;
    top: -10%;
    position: relative;
    border-radius: 1px;
    @media (max-width: 990px) {
      position: unset;
      margin: 20px auto;
    }
  }
}
.section-4,
.section-3 {
  .component {
    margin: 0 0 25px 0;
    h5 {
      font-family: "Avenir-Medium";
    }
  }
}
.text-border-brake {
  width: 110px;
}
.section-six {
  .container {
    padding: 110px 0;
    .mobile {
      padding: 110px 0;
      height: 650px;
      @media (max-width: 991px) {
        height: auto;
        width: 65%;
        max-width: 320px;
      }
    }
    .m-l-txt {
      padding-top: 110px;
    }
    @media (min-width: 1024px) {
      padding: 200px 0;
    }
  }
}

.who-can-use-us-container {
  background: white;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 990px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.who-can-use-us {
  margin: 0 10%;
  @media (max-width: 990px) {
    margin: 0 6.5%;
  }

  .who-can-desktop img {
    height: 80vh;
    max-width: 100%;
  }
  .hex-inner-text {
    font-family: $font-family2;
    font-weight: 900;
    letter-spacing: 0.8px;
    text-align: center;
    color: #333333;
    display: block;
  }
  .base-structure {
    // width: 1250px;
    margin: 0 auto;
    position: relative;
  }
  .first-row {
    // width: 1000px;
    width: fit-content;
    display: flex;
    margin: 0 auto;
    .square {
      top: 30%;
    }
  }
  .second-row {
    display: flex;
    width: fit-content;
    top: -35px;
    position: relative;
    margin: 0 auto;
    .square {
      top: 27%;
    }
    @media (max-width: 1281px) {
      top: -29px;
    }
    .polygon {
      height: 300px;
      max-height: 285px;
      @media (max-width: 1281px) {
        height: 232px;
      }
    }
  }
  .polygon {
    min-width: 180px;
    min-height: 180px;
    display: inline;
    text-align: center;
    background: url(/landingPage/polygon.svg);
    background-size: cover;
    max-width: 250px;
    max-height: 250px;
    height: 250px;
    width: 250px;
    background-repeat: no-repeat;
    position: relative;
    .square {
      position: absolute;
      width: 100%;
      .hex-inside-img {
        margin-bottom: 10px;
      }
    }

    @media (max-width: 1281px) {
      height: 200px;
      width: 200px;
    }
  }
  .square-mid {
    min-width: 180px;
    display: block;
    text-align: center;
  }
}

.scene-creator {
  .section-subpar {
    margin: 10px auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.footer-links {
  a {
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.65);
  }
  a:hover {
    color: rgba(0, 0, 0, 0.65);
  }
}
.section-2 {
  .helper {
    color: #25262b;
    font-size: 12px;
    font-family: $font-family1;
  }
  .text-sec2-cta {
    top: 24%;
    &.mid-res {
      @media (max-width: 990px) {
        position: unset;
        height: unset;
      }
      .side-text {
        padding-bottom: 20px;
      }
    }
  }
}
.text-sec2-cta {
  max-width: 395px;
  height: 256px;
  font-family: $font-family1, serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  position: relative;
  color: #25262b;
  top: 32%;
  text-align: initial;
  padding-left: 50px;

  &.mid-res {
    @media (max-width: 990px) {
      width: 100%;
      max-width: none;
      font-size: 1.55em;
      padding-left: 0;
      text-align: center;
    }
  }
}
.full-opacity {
  opacity: 1 !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.grey-bg {
  background: #f4f4f4;
  @media (max-width: 990px) {
    margin: 0 auto;
  }
}
.section-3 {
  margin-top: 80px;
  @media (max-width: 990px) {
    padding: 40px 0;
  }
  .heading {
    // margin-bottom: 20px;
    @media (max-width: 990px) {
      // margin-bottom: 40px;
      font-size: $mobile-heading-size;
      padding: 0 10px;
    }
    .legend {
      font-size: 32x;
      @media (max-width: 990px) {
        font-size: 24px;
      }
    }
  }
  .carousel .slider {
    img {
      // max-height: 75vh;
      @media (max-width: 990px) {
        max-height: none;
      }
    }
  }
  .carousel.carousel-slider {
    overflow: unset;
    .control-arrow {
      height: 200px;
      margin: auto;
    }
  }
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    background: darkgrey;
    @media (max-width: 990px) {
      display: none;
    }
  }

  .carousel .control-dots {
    position: relative;
    bottom: -45px;
    padding: 0;
    .dot {
      // box-shadow: none;
      background-color: #e4e4e4;
      &.selected {
        background: #d93025;
      }
    }
    @media (max-width: 990px) {
      bottom: -35px;
    }
  }
  .legend {
    font-family: $font-family1;
    font-size: 32px;
    position: relative;
    text-align: center;
    top: -40px;
    @media (max-width: 990px) {
      font-size: 1.2em;
      top: -35px;
    }
  }
}
.scene-creator {
  margin: 40px 0;
  .scene-video {
    margin-bottom: 40px;
    video {
      max-width: 100%;
      height: auto;
    }
  }
}

.why-choose-us-container {
  background: #f7f4ee;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 990px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.why-choose-us {
  margin: 0 10%;
  @media (max-width: 990px) {
    margin: 0 6.5%;
  }
}

.mt-80 {
  margin-top: 80px;
}
.mt-40 {
  margin-top: 40px;
}

.price-container {
  position: relative;
  top: 200px;
  .price-box {
    display: block;
    font-size: 60px;
    text-align: center;
    border-radius: 6px;
    border: solid 2px #f4f4f4;
    background-color: #ffffff;
    letter-spacing: 2.4px;
    padding: 40px 20px;
    .price-tag {
      font-family: $font-family2;
      font-weight: 900;
    }
    .price-cta,
    .price-list-text {
      font-size: 12px;
    }
    .price-list-text {
      margin: 10px 0;
    }
  }
}

#root {
  height: 100%;
}
.coming-soon {
  position: relative;
  top: 35%;
  min-height: calc(100% - 330px);
  .cm-text {
    text-align: center;
    color: #e15a5f;
    font-weight: 900;
    font-family: $font-family2;
    font-size: 40px;
  }
}

.download-cta {
  min-width: 290px;
  margin: 0 auto 30px;
  @media (max-width: 990px) {
    margin-top: 50px;
  }
  .fix-download-box {
    display: inline-block;
    .logo-img {
      height: 100px;
      margin: auto;
      position: relative;
      @media (max-width: 990px) {
        position: initial;
      }
    }
  }
  .down-cta-box {
    @media (max-width: 990px) {
      margin-top: 10px;
    }
    img {
      height: 60px;
    }
  }
}

.price-container-left {
  width: 34vw;
  cursor: pointer;
  &:hover {
    background: rgba(250, 250, 250, 0.5);
    border: 1px solid #eeeeee;
    box-sizing: border-box;
  }
}

.border-class {
  border-bottom: 1px solid #c4c4c4;
}

.price-container-active {
  background: #fafafa;
  border-bottom: none;
  border-left: 5px solid #d93025;
  &:hover {
    background: #fafafa;
    border: none;
    border-bottom: none;
    border-left: 5px solid #d93025;
  }
}

.price-box {
  flex: none;
  align-self: flex-start;
  margin: 0px 15px;

  .price-start {
    font-family: $font-family2;
    padding-top: 15px;
    font-size: 20px;
    line-height: 32px;
    text-transform: capitalize;
    color: #737373;
    margin-left: 20px;
  }
  .price {
    width: 70px;

    font-family: $font-family4;
    font-size: 40px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    padding-top: 15px;
    color: #d93025;
    padding-bottom: 30px;
    margin-left: 20px;
  }
}

.visibility-none {
  opacity: 0;
  visibility: hidden;
  height: 0;
  background-color: rgba(92, 91, 87, 0.9);
  -webkit-transition: opacity 300ms, visibility 200ms;
  transition: opacity 300ms, visibility 200ms;
}
.visibility-block {
  display: block;
  visibility: visible;
  opacity: 1;
  animation: fade 1s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.category-name {
  font-size: 18px;
  font-weight: normal;
}

.category-price {
  font-size: 18px;
  color: #276dd7;
  // margin-left: 50px;
  font-weight: bold;
}

.price-model {
  font-weight: 500;
  font-size: 24px;
  font-family: $font-family5;
  line-height: 32px;
  color: #333333;
  padding-top: 30px;
  margin-left: 20px;
}

.category-heading {
  font-size: 20px;
  line-height: 32px;
  font-family: $font-family5;
  margin-top: 30px;
  width: 117px;
  height: 32px;
  color: #333333;
  margin-left: 20px;
}

@media (max-width: 1199px) {
  .pricing-header .pricing-text {
    font-size: 48px;
    margin-right: auto;
    margin-left: auto;
    width: 77vw;
    // width: 70%;
  }
  .price-model {
    font-size: 24px;
  }

  .category-heading {
    font-size: 20px;
  }
}

@media (max-width: 1023px) {
  //.pricing-header .pricing-text {
  // font-size: 40px;
  // margin-right: auto;
  // margin-left: auto;
  // width: 70%;
  //}
  .price-model {
    font-size: 24px;
  }

  .category-heading {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .row-small-screen {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .pricing-header {
    //  width: 80%;
    width: 77vw;
    margin-bottom: 0px;
  }
  .detail {
    margin-left: auto;
    margin-right: auto;
    //width: 660px;
    width: 70%;
  }

  .custom-border {
    border-bottom: none;
  }
  .row-large-screen {
    display: none !important;
  }

  .price-container-center-active {
    background: white;
    border-left: none;
    border-bottom: none;
    // &:hover {
    //   background: #fafafa;
    //   border:none;
    //   border-bottom: none;
    //   border-left: 5px solid #D93025;
    // }
  }

  .category-text {
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    color: #333333;
    margin-left: 0px;
    margin-top: 20px;
    font-family: $font-family5;
  }

  .prices {
    margin-top: 18px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #737373;
    margin-left: 0px;
  }

  .bottom-border {
    //width: 660px;
    width: 100%;
    text-align: center;
    //margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    border-bottom: 1px solid #c4c4c4;
  }

  .circle {
    background: #fafafa;
    border-radius: 110px;
    width: 40px;
    height: 40px;
    float: right;
    //margin: auto;
    text-align: center;
    cursor: pointer;
  }
  .circle-red {
    background: #d93025;
    border-radius: 110px;
    width: 40px;
    height: 40px;
    float: right;
    //margin: auto;
    text-align: center;
    cursor: pointer;
  }

  .price-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #737373;
    // padding-top: 45px;
    font-family: $font-family5;
    //margin-left: 20px;
  }
  .price-start {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    padding-top: 28px;
    //  margin-left: 20px;
  }
  .price {
    margin-left: 10px;
    color: #d93025;
    font-weight: bold;
    font-family: $font-family4;
    font-size: 28px;
    letter-spacing: 0.04em;
  }

  .price-container-center {
    // width: 660px;
    width: 70%;
    cursor: pointer;

    margin: auto;
  }

  .price-description {
    padding-top: 15px;
    // margin-left: 20px;
    text-transform: capitalize;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-transform: capitalize;
    color: #737373;
  }

  .space-mobile {
    margin-left: auto;
    margin-right: auto;
    //padding-left: 20px;
    // width: 660px;
    width: 70%;
    color: #737373;
    font-family: "Avenir";
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .space-title-mobile {
      font-weight: 900;
      font-size: 18px;
      line-height: 32px;
      text-transform: capitalize;
      margin-bottom: 20px;
      color: #737373;
    }
    .space-price-mobile {
      font-weight: 900;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      //margin-left: 50px;
      text-transform: capitalize;
      color: #276dd7;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 575px) {
  // .pricing-header {
  //   .pricing-text {
  //     font-size: 32px;
  //   }
  // }

  .space-mobile .space-title-mobile {
    font-size: 16px;
  }

  .space-mobile .space-price-mobile {
    font-size: 16px;
  }

  .price-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #737373;
    //padding-top: 45px;
  }
  .price-start {
    font-size: 18px;
  }
  .price {
    font-size: 24px;
  }

  .prices {
    margin-top: 18px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #737373;
    margin-left: 0px;
  }

  .category-name {
    font-size: 16px;
  }

  .category-price {
    font-size: 16px;
  }

  .price-description {
    padding-top: 15px;
    text-transform: capitalize;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-transform: capitalize;
    color: #737373;
  }

  .row-large-screen {
    display: none !important;
  }
}

.details {
  background: #ffffff;
  flex: none;
  margin: 0px 15px;
  .price-description {
    font-family: $font-family2;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #737373;
    margin-left: 20px;
    width: 34vw;
  }
}

.space-header {
  margin-top: 30px;
  font-family: $font-family2;
  font-size: 24px;
  background: white;
  width: 34vw;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  border-bottom: 1px solid #c4c4c4;

  .space-title {
    font-size: 18px;
    color: #737373;
    margin-bottom: 30px;
  }
  .space-price {
    color: #276dd7;
    font-size: 18px;
    font-weight: bold;
    // margin-left: 50px;
    margin-bottom: 30px;
  }
}

.navbar-active {
  border-bottom: 3px solid #d93025;
}
@media (max-width: 1439px) {
  .price-head-container {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .price-head-container-sm {
    display: none;
  }

  .pricing-header {
    width: 77vw;
    margin-top: 140px;
    margin-bottom: 0px;
    .pricing-text {
      text-align: left;
      margin: 30px 60px;
      margin-top: 32px;
      margin-bottom: 15px;
      color: #25262b;
      font-weight: bold;
      font-size: 50px;
      font-family: $font-family5;
      line-height: 100%;
    }
  }

  #contact-us-button {
    margin-right: 60px;
    margin-left: 60px;
    float: left;
    margin-bottom: 30px;
    background: #d93025;
    border-radius: 6px;
    height: 50px;
    border: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .button-text {
    font-family: $font-family3;
    padding: 17.5px 27.5px;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #ffffff;
  }
}
@media (max-width: 991px) {
  .price-head-container {
    display: none;
  }
  .price-head-container-sm {
    display: block;
  }
  .pricing-header {
    width: 77vw;
    margin-top: 140px;
    margin-bottom: 0px;
    .pricing-text {
      text-align: left;
      margin: 30px 60px;
      margin-bottom: 15px;
      margin-top: 32px;
      color: #25262b;
      font-weight: bold;
      font-size: 50px;
      font-family: $font-family5;
      line-height: 100%;
    }
  }

  #contact-us-button {
    margin-right: 60px;
    margin-left: 60px;
    float: left;
    margin-bottom: 30px;
    background: #d93025;
    border-radius: 6px;
    height: 50px;
    border: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .button-text {
    font-family: $font-family3;
    padding: 17.5px 27.5px;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #ffffff;
  }
}
.pricing-desc {
  text-align: justify;
  margin-bottom: 30px;
  margin-right: 60px;
  margin-left: 60px;
  font-size: 24px;
  font-family: $font-family5;
  color: #737373;
}

@media (min-width: 1440px) {
  .price-head-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-left: 124px;
    margin-right: 124px;
    justify-content: center;
  }
  .price-head-container-sm {
    display: none;
  }
  .pricing-header {
    //width: 70.5vw;
    margin-top: 135px;
    margin-bottom: 0px;
    .pricing-text {
      text-align: left;
      color: #25262b;
      font-weight: bold;
      margin: 30px 0 15px 32px;
      font-size: 50px;
      font-family: $font-family5;
      line-height: 64px;
    }
  }
  .pricing-desc {
    //width: 45vw;
    line-height: 140%;
    text-align: justify;
    margin-bottom: 30px;
    margin-left: 32px;
    font-size: 24px;
    font-family: $font-family5;
    color: #737373;
  }

  #contact-us-button {
    //margin-right: 60px;
    float: left;
    margin-left: 32px;
    background: #d93025;
    border-radius: 6px;
    height: 50px;
    border: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .button-text {
    font-family: $font-family3;
    padding: 17.5px 27.5px;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
  }
}

@media (max-width: 767px) {
  .pricing-header .pricing-text {
    font-size: 40px;
  }
  .pricing-desc {
    margin-right: 60px;
    margin-left: 60px;
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .pricing-desc {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 18px;
  }
  .pricing-header .pricing-text {
    font-size: 40px;
    margin: 30px 20px;
  }
}

.active {
  border-bottom: 3px solid #25262b;
  color: #25262b;
}

.category-wrapper {
  background: #fafafa;
  padding: 6px 20px;
  border-radius: 4px;
}

@media (min-width: 992px) {
  .sticky-column {
    position: sticky !important;
    top: 4rem !important;
    overflow: hidden !important;
  }

  #one {
    // position: absolute;
    // top: 0px;
    // right: 0px;
  }
  #three {
    top: 0;
  }
}

.activeBtn {
  background: #25262b !important;
  color: white !important;
  cursor: not-allowed !important;
  &:hover {
    box-shadow: none !important;
  }

  &.disabled,
  &:disabled {
    opacity: unset;
  }
}
.centerCarousel {
  margin: auto;
}

.CookieConsent {
  background: rgba(255, 255, 255, 0.8) !important;
  font-family: Roboto !important;
  color: #333333 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  padding: 0 17px !important;
  align-items: center !important;
}

@media (max-width: 575px) {
  // .footer-line {
  //   padding: 40px 15px;
  // }
  .footer-container {
    padding: 16px 0px;
    padding-bottom: 0px;
  }
  .about-us-footer {
    margin-bottom: 16px !important;
  }
  .nutshell-container {
    padding: 30px 0px;
  }

  .nutshell-bg .container {
    padding: 80px 70px;
  }
  // .who-can-use-us {
  //   margin: 120px auto;
  //   margin-bottom: 32px;
  // }
  .section-six .container {
    padding: 80px 0;
  }
  .pricing-header {
    margin-top: 117px;
  }
  #contact-us-button {
    margin-right: 20px;
    margin-left: 20px;
  }
  .CookieConsent {
    background: rgba(255, 255, 255, 1) !important;
  }
  .margin-above-footer {
    margin-bottom: 0px;
  }
}

@media (max-width: 510px) {
  .CookieConsent {
    flex-direction: row-reverse !important;
  }
}

.carousel .slide {
  background: transparent !important;
}

.section-six .header-text {
  font-family: "Avenir-Roman";
  font-size: 20px;
  margin-bottom: 10px;
  color: #737373;
}
@media (min-width: 1024px) {
  .scene-text-padding {
    padding-bottom: 36px;
  }
  .padding-3d-assets {
    padding-right: 60px;
  }
  .lifestyle-margin-right {
    margin-right: 120px;
  }
  .lifestyle-image-padding {
    padding-right: 60px !important;
  }
  .product-text-padding {
    padding-right: 60px;
  }
  .footer-line .socialIcons .iconHolder {
    text-align: center;
  }
  .make-centered {
    text-align: center;
  }
  .pricing-header .pricing-text {
    margin-bottom: 15px;
  }
  .nutshell-bg .container {
    padding: 80px 70px;
    position: relative;
  }
}

.apply-Avenir {
  font-family: Avenir-Book;
  // font-style: Avenir-Book;
}

.store-heading {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  color: #333333;
}

.btn-outline-red {
  color: #333333;
  background-color: transparent;
  background-image: none;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: 900;
  line-height: 1.57;
  letter-spacing: 1px;
  text-align: center;
  border: 1px solid #333333;
  border-radius: 25px;

  &:hover {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    color: #333333;
  }
}
@media (min-width: 992px) {
  .pagination-m {
    display: none;
  }
  .pagination-w {
    display: block;
  }
}
@media (max-width: 991px) {
  .pagination-m {
    display: block;
  }
  .pagination-w {
    display: none;
  }
}

.image-zoom {
  // height: calc(100vh - 202px) !important;
  height: calc(100vh - 279px) !important;

  width: auto !important;
  object-fit: scale-down;
}
#zoom-image {
  div {
    img {
      width: auto !important;
    }
  }
}

@media (max-width: 1200px) {
  .scan-col {
    margin: auto;
  }
}

.manrope {
  font-family: Manrope;
  font-style: normal;
  &.f-50 {
    font-size: 50px;
    font-weight: 700;
    line-height: 120%;
  }

  &.f-46 {
    font-weight: 700;
    font-size: 46px;
    letter-spacing: 0;
    line-height: 120%;
  }
  &.f-38 {
    font-weight: 600;
    font-size: 38px;
    letter-spacing: 0;
    line-height: 120%;
  }
  &.f-36 {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-32 {
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 120%;
  }
  &.f-28 {
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-24 {
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-22 {
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-20 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }

  &.f-18 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  }

  &.f-16 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &.f-14 {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 120%;
    letter-spacing: 2%;
  }

  &.f-12 {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  &.f-8 {
    font-weight: 500;
    font-size: 8px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  &.clamp-text {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 145px;
    &.w-50 {
      max-width: 50%;
      width: unset;
    }
    &.w-60 {
      width: 60%;
      max-width: 50%;
      width: unset;
    }
    &.w-90 {
      width: 90%;
      text-align: left;
    }
  }

  &.clamp-w-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.w-400 {
    font-weight: 400;
  }

  &.w-600 {
    font-weight: 600;
  }

  &.w-700 {
    font-weight: 700;
  }

  &.w-900 {
    font-weight: 900;
  }

  &.italic {
    font-style: italic;
  }

  &.green {
    color: #009d19;
  }

  &.green-75 {
    color: #75d8a5;
  }

  &.black-55 {
    color: #555555 !important;

    .ant-legacy-form-item-label > label {
      color: #555555 !important;
    }
  }

  &.black-14 {
    color: #141414;
  }

  &.black-00 {
    color: #000000;
  }

  &.black-73 {
    color: #00000073;
  }

  &.black-33 {
    color: #333333;
  }

  &.red {
    color: #ff4a4d;
  }

  &.red-error {
    color: #e54547;
  }

  &.red-d9 {
    color: #d93025;
  }

  &.red-ff {
    color: #ff4d4f;
  }

  &.red-03 {
    color: #d40003;
  }

  &.grey-73 {
    color: #737373;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.grey-99 {
    color: #999999;
  }

  &.grey-6b {
    color: #6b6b6b;
  }

  &.grey-77 {
    color: #777777;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.white {
    color: #ffffff;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.blue {
    color: #276dd7;
    &.link {
      text-decoration: underline;
      cursor: pointer;
    }
    &.hover-change {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.blue-gradient {
    background: linear-gradient(93.3deg, #7883DC 9.81%, #276DD7 52.44%, #70C9FF 95.08%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent !important;
  }


  &.j-end {
    display: flex;
    align-items: flex-end;
    text-align: center;
  }

  &.j-center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &.upper-case {
    text-transform: uppercase;
  }

  &.underline {
    text-decoration: underline;
  }
  &.text-shadow {
    text-shadow: 0 2px 25px rgba(0, 0, 0, 0.5);
  }
}

.tree-select-material {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  letter-spacing: 2%;
  line-height: 100%;
  &.f-12 {
    font-size: 12px;
  }
  &.f-16 {
    font-size: 16px;
  }
  &.bg {
    .ant-select-selector {
      width: 100% !important;
      background-color: #dfdfdf;
      border-color: #dfdfdf;
      height: 42px !important;
      border-radius: 4px !important;
    }
  }

  &.item-height-35 {
    .ant-select-selection-item {
      height: unset !important;
      line-height: 35px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .ant-select-selector {
    height: 45px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .ant-select-selection-search {
      height: unset !important;
      padding: 14px 16px !important;
      padding-left: 0px !important;
      line-height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .ant-select-selection-search-input {
        height: unset !important;
        padding: 0px 16px !important;
        padding-left: 0px !important;
        line-height: 45px;
      }
    }
    .ant-select-selection-item {
      height: unset !important;
      line-height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      height: unset !important;
      line-height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &.lh-50 {
    .ant-select-selector {
      height: 50px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .ant-select-selection-search {
        height: unset !important;
        padding: 14px 16px !important;
        padding-left: 0px !important;
        line-height: 45px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .ant-select-selection-search-input {
          height: unset !important;
          padding: 0px 16px !important;
          padding-left: 0px !important;
          line-height: 45px;
        }
      }
      .ant-select-selection-item {
        height: unset !important;
        line-height: 45px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .ant-select-selection-placeholder {
        height: unset !important;
        line-height: 45px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  &.filter {
    width: 100% !important;

    .ant-select-selector {
      background-color: #dfdfdf;
      border-color: #dfdfdf;
      border-radius: 4px;
      color: #555555;
      min-height: 45px;
      height: auto !important;
    }
    &.bg-light {
      .ant-select-selector {
        padding-left: 8px !important;
        background-color: #f7f7f8;
        border-color: #f7f7f8;
        .ant-select-selection-search {
          margin-inline-start: 0px;
          font-size: 12px;
          height: 10px !important;
          padding: 0px 0px 0px !important;
          .ant-select-selection-search-input {
            padding: 0px !important;
            margin-left: 2.5px !important;
          }
        }
        .ant-select-selection-overflow {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
      .ant-select-selection-item {
        .ant-select-selection-item-remove {
          display: flex !important;
        }
      }
    }
    .ant-select-selection-item {
      height: 35px !important;
      border-color: #dfdfdf;
      background-color: #fafafa;
      font-size: 12px;
      border-radius: 16px;
    }
    .ant-select-selection-overflow-item-suffix {
      height: 10px !important;
    }
  }
}

.modal-okay-blue {
  width: unset !important;
  height: unset !important;
  padding: 0 !important;
  background: #ffffff !important;
  color: #276dd7 !important;
  border: 1px solid #276dd7 !important;
  border-radius: 35px !important;
  &.ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background: #ffffff !important;
    text-shadow: none;
    opacity: 0.5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    background: #276dd7 !important;
    border: 1px solid #276dd7 !important;
    color: white !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
      background: white !important;
      border: 1px solid #276dd7 !important;
      //  color: #ffffff !important;
    }
    &:hover {
      background: #276dd7 !important;
      border: 1px solid #276dd7 !important;
      color: white !important;
    }
    &.ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
      background: white !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.ant-tooltip-disabled-compatible-wrapper {
      background: white !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 11px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;
      }
    }
  }

  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.small {
      padding: 12px 15px !important;
    }
  }

  &.font-16 {
    font-size: 16px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
  }
}

.modal-okay-price {
  border: none !important;
  background: #276dd7 !important;
  border-radius: 35px !important;
  padding: 5 5 !important;
  color: white !important;
  &.square {
    border-radius: 4px !important;
    &:hover {
      background: #205fcf !important;
      border: none !important;
      color: #ffffff !important;
    }
    &:focus {
      border: none !important;
      background: #276dd7 !important;
      color: white;
    }

    &:active {
      border: none !important;
      background: #276dd7 !important;
      color: white;
    }
  }
}

.modal-okay-brown {
  border: none !important;
  background: #c2a374 !important;
  border-radius: 35px !important;
  padding: 5 5 !important;
  color: white !important;
  &.square {
    border-radius: 4px !important;
    &:hover {
      background: #a78e69 !important;
      border: none !important;
      color: #ffffff !important;
    }
    &:focus {
      border: none !important;
      background: #c2a374 !important;
      color: white;
    }

    &:active {
      border: none !important;
      background: #c2a374 !important;
      color: white;
    }
  }
}

.modal-okay {
  width: unset !important;
  height: fit-content !important;
  border: none !important;
  background: #276dd7 !important;
  border-radius: 35px !important;
  padding: 0 !important;
  color: white !important;
  &.round {
    border-radius: 50% !important;
    padding: 6px 10px !important;
  }
  &:hover {
    background: #205fcf !important;
    border: none !important;
    color: #ffffff !important;
  }
  &:focus {
    border: none !important;
    background: #276dd7 !important;
    color: white !important;
  }
  &:active {
    border: none !important;
    background: #276dd7 !important;
    color: white !important;
  }
  &.ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background: #276dd7 !important;
    opacity: 0.5 !important;
    text-shadow: none;
    color: white !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.square {
    border-radius: 4px !important;
    &:hover {
      background: #205fcf !important;
      border: none !important;
      color: #ffffff !important;
    }
    &:focus {
      border: none !important;
      background: #276dd7 !important;
      color: white;
    }

    &:active {
      border: none !important;
      background: #276dd7 !important;
      color: white;
    }

    &.ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
      background: #276dd7 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      color: white !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.ant-tooltip-disabled-compatible-wrapper {
      background: #276dd7 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.ai-gradient {
      background: var(--AI-gradient, linear-gradient(108deg, #7883DC -7.35%, #276DD7 45.17%, #91D5FF 97.69%)) !important;
      &:hover {
        background: var(--AI-gradient, linear-gradient(108deg, #7883DC -7.35%, #276DD7 45.17%, #91D5FF 97.69%)) !important;
      }
      &:focus {
        background: var(--AI-gradient, linear-gradient(108deg, #7883DC -7.35%, #276DD7 45.17%, #91D5FF 97.69%)) !important;
      }
  
      &:active {
        background: var(--AI-gradient, linear-gradient(108deg, #7883DC -7.35%, #276DD7 45.17%, #91D5FF 97.69%)) !important;
      }
  
      &.ant-btn[disabled],
      .ant-btn[disabled]:hover,
      .ant-btn[disabled]:focus,
      .ant-btn[disabled]:active {
        background: var(--AI-gradient, linear-gradient(108deg, #7883DC -7.35%, #276DD7 45.17%, #91D5FF 97.69%)) !important;
      }
      &.ant-tooltip-disabled-compatible-wrapper {
        background: var(--AI-gradient, linear-gradient(108deg, #7883DC -7.35%, #276DD7 45.17%, #91D5FF 97.69%)) !important;
      }
    }
  }
  &.font-16 {
    font-size: 16px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    // text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
  }
  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.small {
      padding: 12px 15px !important;
    }
  }
  &.font-18 {
    font-size: 18px !important;
    line-height: 120%;
    padding: 16px 32px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.w-900 {
      font-weight: 900;
    }
  }
  &.font-14 {
    font-size: 14px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.mat-pd {
      padding: 14px 35px !important;
    }
    &.pd {
      padding: 13px 75px !important;
      @media (max-width: 1366px) {
        padding: 13px 25px !important;
        font-size: 12px !important;
      }
      @media (max-width: 1440px) {
        font-size: 14px !important;
        padding: 13px 50px !important;
      }
    }
  }
  &.center-btn {
    width: 100% !important;
    justify-content: center;
    font-weight: 700;
    padding: 20px 24px !important;
    &.pdt-16 {
      padding: 16px 24px !important;
    }
  
    &.width-fit {
      width: fit-content !important;
    }
  }
}

.justify-space-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.justify-space-around {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center;
}

.direction-flex {
  &.roww {
    flex-direction: row;
  }
  &.coll {
    flex-direction: column;
  }
}

.tag-filter {
  background: rgba(0, 0, 0, 0.06) !important;
  border-radius: 4px !important;
  padding: 12px !important;
  .ant-tag {
    background: rgba(0, 0, 0, 0.06) !important;
    border-radius: 4px !important;
    padding: 12px !important;
  }
}

.select-view {
  line-height: 188% !important;
  .ant-radio-button-wrapper {
    .ant-radio-button-wrapper-checked {
      &:hover {
        color: white !important;
      }
    }

    &:hover {
      color: #333333 !important;
    }
  }
}

.justify-in-center {
  display: flex;
  justify-content: center;
  align-items: center;
  &.wrap {
    flex-wrap: wrap;
  }
}

.justify-in-start {
  display: flex;
  justify-content: start;
  align-items: center;
}

.justify-in-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.product-photo-card {
  max-width: 160px;
  height: 160px !important;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  cursor: pointer;
  .ant-card-body {
    padding: 0px;
  }
  &.large {
    max-width: 100%;
  }
  &.floorplan {
    max-width: 100%;
    text-align: center;
  }
  &.w-auto {
    max-width: max-content;
    height: unset !important;
  }
  @media (max-width: 767px) {
    max-width: 80px;
    height: 80px !important;
    &.floorplan {
      max-width: 100px;
      height: 80px !important;
      max-width: 100%;
      text-align: center;
    }
  }
}

.product-photo-image {
  width: 100%;
  height: 160px !important;
  object-fit: cover;
  .ant-image-img {
    width: 158px !important;
    height: 158px !important;
    object-fit: contain;
  }
  @media (max-width: 767px) {
    height: 78px !important;
    .ant-image-img {
      width: 78px !important;
      height: 78px !important;
    }
    &.floorplan {
      .ant-image-img {
        width: 128px !important;
        height: 78px !important;
      }
    }
  }
}

.j-start-a-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

#check > span {
  background: rgba(39, 109, 215, 0.12);
  border-radius: 5px;
  width: "18px";
  height: "15px";
}

#check > span > svg {
  color: #276dd7;
}

#check-brown > span {
  background: rgba(194, 163, 116, 0.12);
  border-radius: 5px;
  width: "18px";
  height: "15px";
}

#check-brown > span > svg {
  color: #867151;
}

.proxima-nova {
  &.nova-light {
    font-family: ProximaNova-Light;
  }
  &.nova-semibold {
    font-family: ProximaNova-Semibold;
  }
  &.nova-bold {
    font-family: ProximaNova-Bold;
    &.f-40 {
      font-weight: 700;
      font-size: 40px;
      letter-spacing: 0;
      line-height: 48px;
    }
    &.f-18 {
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 48px;
    }
  }
  &.nova-regular {
    font-family: ProximaNova-Regular;
    &.f-14 {
      font-weight: 300;
      font-size: 14px;
      line-height: 120%;
    }
    &.f-16 {
      font-weight: 300;
      font-size: 16px;
      line-height: 120%;
    }
    &.f-12 {
      font-weight: 300;
      font-size: 12px;
      line-height: 120%;
    }
  }
  font-style: normal;
  &.f-64 {
    font-weight: 600;
    font-size: 64px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-48 {
    font-weight: 600;
    font-size: 48px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-40 {
    font-weight: 600;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-36 {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-32 {
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 120%;
  }
  &.f-28 {
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-24 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-20 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }

  &.f-18 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  }

  &.f-16 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &.f-14 {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 120%;
    letter-spacing: 2%;
  }

  &.f-12 {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  &.f-8 {
    font-weight: 500;
    font-size: 8px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  &.clamp-text {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 145px;
  }

  &.clamp-w-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.justify {
    text-align: justify;
  }

  &.w-400 {
    font-weight: 400;
  }

  &.w-600 {
    font-weight: 600;
  }

  &.w-700 {
    font-weight: 700;
  }

  &.w-300 {
    font-weight: 300;
  }

  &.w-900 {
    font-weight: 900;
  }

  &.italic {
    font-style: italic;
  }

  &.green {
    color: #009d19;
  }

  &.green-75 {
    color: #75d8a5;
  }

  &.brown {
    color: #c2a374;
    &.decorate {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.brown-b1 {
    color: #b19264;
  }

  &.black-55 {
    color: #555555 !important;

    .ant-legacy-form-item-label > label {
      color: #555555 !important;
    }
  }

  &.black-2b {
    color: #2b3240 !important;
  }

  &.black-14 {
    color: #141414;
  }

  &.black-00 {
    color: #000000;
  }

  &.grey-light {
    color: rgba(43, 50, 64, 0.8);
  }

  &.black-33 {
    color: #333333;
  }

  &.red {
    color: #ff4a4d;
  }

  &.red-error {
    color: #e54547;
  }

  &.red-d9 {
    color: #d93025;
  }

  &.red-ff {
    color: #ff4d4f;
  }

  &.red-03 {
    color: #d40003;
  }

  &.grey-73 {
    color: #737373;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.grey-99 {
    color: #999999;
  }

  &.grey-6b {
    color: #6b6b6b;
  }

  &.grey-77 {
    color: #777777;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.white {
    color: #ffffff;
  }
  &.h-underline {
    &:hover {
      text-decoration: underline;
    }
  }
  &.capitalize {
    text-transform: capitalize;
  }

  &.blue {
    color: #276dd7;
    &.link {
      text-decoration: underline;
      cursor: pointer;
    }
    &.hover-change {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.j-end {
    display: flex;
    align-items: flex-end;
    text-align: center;
  }

  &.j-center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &.upper-case {
    text-transform: uppercase;
  }

  &.underline {
    text-decoration: underline;
  }
  &.text-shadow {
    text-shadow: 0 2px 25px rgba(0, 0, 0, 0.5);
  }
}

.reference-img-card {
  max-width: 100px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  height: 105px;
  cursor: pointer;
}

.reference-img-photo {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.list-pd {
  padding-inline-start: 16px;
}

.social-icons {
  width: 24px;
  height: 18px;
  &.big {
    width: 36px;
    height: 36px;
  }
  @media (max-width: 991px) {
    width: 20px;
    height: 14px;
  }
}

#read_more {
  display: none;
  @media (max-width: 991px) {
    display: inline;
  }
}

#dots {
  @media (max-width: 991px) {
    display: none;
  }
}
#read {
  @media (max-width: 991px) {
    display: none;
  }
}

.featured-in-img {
  width: 100%;
  object-fit: contain;
  height: 37px;
}

.get-started-btn-white {
  display: block;
  padding: 20px;
  background: white;
  border: none;
  border-radius: 4px;
  pointer-events: all;
  &:hover {
    // background-color: #C2A374;
    // color: white !important;
  }
  &:not(:disabled):not(.disabled):active {
    border: none;
  }

  @media (max-width: 991px) {
    padding: 12px 16px;
  }
  a:hover {
    text-decoration: none;
  }
}

.footer-sec-1-pd {
  &.right {
    padding-top: 70px;
    padding-bottom: 70px;
    padding-right: 5%;
    padding-left: 5%;
  }
  &.left {
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 10%;
  }
}

.footer-sec-2-pd {
  padding: 30px 10%;
  background: #302f3d;
}

.footer-sec-3-pd {
  padding: 30px 10%;
  background: #23222d;
}

.reason-card {
  .ant-card-body {
    background: rgba(194, 163, 116, 0.06);
    border: 1px solid rgba(194, 163, 116, 0.2);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.font {
  &.nova-light {
    font-family: ProximaNova-Light;
  }
  &.nova-semibold {
    font-family: ProximaNova-Semibold;
  }
  &.nova-bold {
    font-family: ProximaNova-Bold;
    &.f-40 {
      font-weight: 700;
      font-size: 40px;
      letter-spacing: 0;
      line-height: 48px;
    }
    &.f-18 {
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 48px;
    }
  }
  &.nova-regular {
    font-family: ProximaNova-Regular;
    &.f-14 {
      font-weight: 300;
      font-size: 14px;
      line-height: 120%;
    }
    &.f-16 {
      font-weight: 300;
      font-size: 16px;
      line-height: 120%;
    }
    &.f-12 {
      font-weight: 300;
      font-size: 12px;
      line-height: 120%;
    }
  }
  &.graphik-regular {
    font-family: Graphik-Regular;
  }
  &.graphik-medium {
    font-family: Graphik-Medium;
  }
  &.manrope-f {
    font-family: Manrope;
  }
  &.lora {
    font-family: Lora;
  }
  font-style: normal;
  &.f-64 {
    font-weight: 600;
    font-size: 64px;
    letter-spacing: 0;
    line-height: 120%;
  }
  &.f-50 {
    font-weight: 600;
    font-size: 50px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-40 {
    font-weight: 600;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-36 {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 120%;
  }

  &.f-32 {
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 120%;
  }
  &.f-28 {
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-24 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &.f-20 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }

  &.f-18 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  }

  &.f-16 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &.f-14 {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 120%;
    letter-spacing: 2%;
  }

  &.f-12 {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  &.f-8 {
    font-weight: 500;
    font-size: 8px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  &.clamp-text {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 145px;
  }

  &.clamp-w-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.justify {
    text-align: justify;
  }

  &.w-400 {
    font-weight: 400;
  }

  &.w-600 {
    font-weight: 600;
  }

  &.w-700 {
    font-weight: 700;
  }

  &.w-300 {
    font-weight: 300;
  }

  &.w-900 {
    font-weight: 900;
  }

  &.italic {
    font-style: italic;
  }

  &.green {
    color: #009d19;
  }

  &.green-75 {
    color: #75d8a5;
  }

  &.brown {
    color: #c2a374;
    &.decorate {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.blue-gradient {
    background: linear-gradient(93.3deg, #7883DC 9.81%, #276DD7 52.44%, #70C9FF 95.08%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent !important;
  }

  &.brown-b1 {
    color: #b19264;
  }

  &.black-55 {
    color: #555555 !important;

    .ant-legacy-form-item-label > label {
      color: #555555 !important;
    }
  }

  &.black-2b {
    color: #2b3240 !important;
  }

  &.black-14 {
    color: #141414;
  }

  &.black-00 {
    color: #000000;
  }

  &.grey-light {
    color: rgba(43, 50, 64, 0.8);
  }

  &.black-33 {
    color: #333333;
  }

  &.red {
    color: #ff4a4d;
  }

  &.red-error {
    color: #e54547;
  }

  &.red-d9 {
    color: #d93025;
  }

  &.red-ff {
    color: #ff4d4f;
  }

  &.red-03 {
    color: #d40003;
  }

  &.grey-73 {
    color: #737373;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.grey-99 {
    color: #999999;
  }

  &.grey-6b {
    color: #6b6b6b;
  }

  &.grey-77 {
    color: #777777;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.white {
    color: #ffffff;
  }
  &.h-underline {
    &:hover {
      text-decoration: underline;
    }
  }
  &.capitalize {
    text-transform: capitalize;
  }

  &.blue {
    color: #276dd7;
    &.link {
      text-decoration: underline;
      cursor: pointer;
    }
    &.hover-change {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.j-end {
    display: flex;
    align-items: flex-end;
    text-align: center;
  }

  &.j-center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &.upper-case {
    text-transform: uppercase;
  }

  &.underline {
    text-decoration: underline;
  }
  &.text-shadow {
    text-shadow: 0 2px 25px rgba(0, 0, 0, 0.5);
  }
  &.ws-2 {
    word-spacing: -2px;
  }
  &.ws-6 {
    word-spacing: -6px;
  }
  &.ws-8 {
    word-spacing: -8px;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    word-spacing: 0px !important;
  }
}
.hyphen-justify {
  text-align: left;
}

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 80px;
  height: 80px;
}
.profile-main-loader .loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.profile-main-loader.container-position {
  display: inline-block !important;
  position: unset !important;
  left: 0% !important;
  margin-left: 0px;
  top: 0% !important;
  margin-top: 80px;
  margin-bottom: 200px;
  width: unset;
  z-index: 10 !important;
}

.dotted-loader-main-div {
  display: inline !important;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: calc(100vh - 165px);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.popup-bg {
  padding-left: 40px;
  padding-right: 24px;
  background-color: #f7f4ee;
  background: #f7f4ee;
  text-align: left;
  @media (max-width: 991px) {
    position: absolute;
    left: 0;
    top: 30%;
    right: 0;
    background: transparent;
    padding: 0px;
  }

  a:hover {
    text-decoration: none;
  }
}

.selling-popup {
  width: 70% !important;
  top: 22% !important;
  @media (max-width: 991px) {
    width: 90% !important;
  }

  .ant-modal-body {
    background: #f7f4ee;
    padding: 0 !important;
  }
  .ant-modal-content {
    .ant-modal-footer {
      display: none !important;
    }
  }
}

.popup-img {
  height: 500px;
  width: 50%;
  object-fit: cover;
  @media (max-width: 991px) {
    height: 450px;
    width: 100%;
  }
}

.popup-btn {
  display: block;
  padding: 20px;
  background: #c2a374;
  border: none;
  border-radius: 4px;
  pointer-events: all;
  &:hover {
    box-shadow: 0 5px 20px 0 rgba(197, 155, 155, 0.3);
    background-color: #c2a374;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: #c2a374;
    border: none;
  }
  &:focus,
  &.focus {
    box-shadow: 0 5px 20px 0 rgba(197, 155, 155, 0.3);
  }

  @media (max-width: 991px) {
    padding: 12px 16px;
  }
}

.modal-okay-light-gray {
  width: unset !important;
  height: fit-content !important;
  border: none !important;
  background: #f7f7f7 !important;
  border-radius: 35px !important;
  padding: 0 !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #737373 !important;
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    color: #737373 !important;
  }

  &:hover {
    background: #e4e4e4 !important;
    border: none !important;
    color: #737373 !important;
  }
  &.square {
    border-radius: 4px !important;
    &:hover {
      background: #e4e4e4 !important;
      border: none !important;
      color: #737373 !important;
    }
    &.ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
      background: #f7f7f7 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.ant-tooltip-disabled-compatible-wrapper {
      background: #f7f7f7 !important;
      opacity: 0.5 !important;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &.font-16 {
      font-size: 16px !important;
      line-height: 120%;
      padding: 12px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      &.w-900 {
        font-weight: 900;
      }
    }

    &.font-14 {
      font-size: 14px !important;
      line-height: 120%;
      padding: 12px 24px !important;
      font-family: Manrope;
      font-style: normal;
      font-weight: 500;
      vertical-align: middle;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      &.w-900 {
        font-weight: 900;
      }
    }
  }
  &.font-12 {
    font-size: 12px !important;
    line-height: 120%;
    padding: 12px 24px !important;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    &.small {
      padding: 12px 15px !important;
    }
  }
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0px !important;
}

model-viewer {
  --progress-bar-color: transparent;
}

.border-brown {
  border:  1px solid #c2a374;
  padding: 16px;
}

.mb-20{
  margin-bottom: 20px;
}

.mb-36{
  margin-bottom: 36px;
}

.mb-42{
  margin-bottom: 42px;
}

.ml-12{
  margin-left: 12px;
}

.layout-snow {
  background: #F9F9F9;
}

.text-danger {
  color: #a94442;
}

.text-center {
  text-align: center;
}

.mt-200 {
  margin-top: 400px;
}

.mb-0 {
  margin-bottom: 0px;
}

.success-modal {
  padding: 0 !important;
  width: 500px !important;
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0 !important;
      text-align: center;
      padding: 0px 30px 30px 30px !important;
      border-top: none !important;
    }
  }
}

.w-100 {
  width: 100%;
}

.close {
  margin: 0 !important;
  line-height: 0px;
}