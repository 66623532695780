
.anticon {
    .anticon-right {
        .ant-collapse-arrow{
            float: right !important;
            font-size: 13px !important;
            position: relative !important;
            -webkit-transform: translateY(0%) !important;
            transform: translateY(0%) !important;
        }
    }
}

.ant-row {
    display: flex;
    justify-content: center;
}

.ant-layout {
    background: white;
}

.collapse-product {
    margin-left: 10px;
    color:#000000;
    font-size: 20px;
    background: white;
    width: 34vw;
}
.collapse-border {
    width: 34vw;
    margin-left: 10px;
}

@media (max-width: 1199px) {
    .collapse-product {
        font-size: 20px;
    }
}

.bg-collapse {
    margin-top: 32px;
}

@media (max-width: 990px) { 
    .collapse-border {
        margin-left: 0px;
        //width: 640px;
        width: 100%;
    }
    .collapse-product {
        margin-left: 0px;        
        background: white;
        //width: 650px;
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
        text-transform: capitalize;
        color: #333333;
    }

    // .bg-collapse {
    //     margin-top: 40px;
    //     //padding-top: 40px;
    // }

    // .ant-collapse-item-active {
    //     background: #fafafa;
    // }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        padding: 0px ;
        color: #737373;
    }

    .ant-collapse .ant-collapse-item {
        .ant-collapse-item-active {
            font-weight: bold;

        }
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0px;
    }
    
}

#contact-us-button:hover {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}