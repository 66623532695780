.about-us-bg {
    filter: brightness(0.8);
    width: 100%;
    height: 100%;
    @media (max-width: 650px) {
        height: 561px;
    }
}

.mission-container {
    position: absolute;
    top: 40%;
    color: white;
    padding: 0 10%;
    @media (max-width: 1200px) {
        top: 30%;
    }
    @media (max-width: 860px) {
        top: 20%;
    }
    @media (max-width: 650px) {
        top: 30%;
    }
}

.text-overlay {
    margin: auto;
    opacity: 0.8;
    background: white;
    margin-top: 12px;
    text-shadow: rgba(0, 0, 0,0.5) 0px 2px 25px;
    width: 100%;
    padding: 4px;
}

.position-member-text {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0px;
}

.team-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .ant-card-body {
        padding: 20px;
        padding: 0px;

    }
}

.about-us-container {
    padding: 0 10%;
    background-color: #FBFBFB;
}

.flag-size {
    width: 100%;
    height: 70px;
    object-fit: contain;
    @media (max-width:991px) {
        height: 40px;
    }
}

.flag-container {
    background: #F7F4EE;
    border-radius: 4px;
    padding: 40px 20px;
    @media (max-width:991px) {
        padding: 24px 20px;
    }
}

.team-picture {
    // filter: brightness(0.7);
    border-radius: 0px;
    height: 320px;
    width: 100%;
    object-fit: cover;
    object-position: unset;
}

.about-us-text {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    // font-size: 1.5vw;
    // line-height: 32px;
    font-size: 24px;
    line-height: 150%;
    color: #FFFFFF;
    &.color-black {
        color: #333333;
    }
}

.about-us-text-bg {
    background: #737373;
    padding: 12% 14%;
    margin-top: 10%;
    z-index: 1;
    position: absolute;
    right: 0px;
    width: 116%;
    height: 82.5%;
}

.who-we-are-bg {
    background: none;
    padding: 12% 14%;
    padding-left: 2%;
}

.what-we-do-text-bg {
    background: #f4f4f4;
    padding: 6.5% 12.3%;
    z-index: 1;
}
.text-border-break {
    border: 2px solid #FFFFFF;
    border-radius: 1px;
    width: 110px;
    &.bg-black {
        border: 2px solid #333333;
    }
}

.nutshell-bg {
    .container-pd {
        padding: 6.7% 13.2%
    }
    .nutshell-f {
        font-size: 24px;
        line-height: 120%;
    }
    
}

#ct-btn {
    width: 150px;
    height: 50px;
    background: #D93025;
    border-radius: 6px;
    border: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover{
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
    }
}

.ct-btn-text {
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #FFFFFF;
}


@media (max-width: 1440px) {
    .about-us-text {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        // font-size: 1.5vw;
        // line-height: 25px;
        font-size: 20px;
        line-height: 120%;
        color: #FFFFFF;
        &.color-black {
            color: #333333;
        }
    }
    .about-us-heading {
        font-size: 32px;
    }
}

.ant-card-cover img{
    border-radius: 15px;
}

.row-section-1{
    justify-content: space-between;
    margin-top: 3%;
}

.row-section-2 {
    margin-top: 4.3%;
}

@media (max-width: 1160px) {
    .about-us-text {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
       font-size: 18px;
        // font-size: 1.5vw;
        // line-height: 21px;
        line-height: 120%;
        color: #FFFFFF;
        &.color-black {
            color: #333333;
        }
    }
    
}

.row-section-3 {
    align-items: center;
    justify-content: space-between;
    margin-top: 6.3%;
}

@media (max-width:991px) {
    .row-section-1{
        //display: unset;
        //justify-content: unset;
        flex-flow: column;
        margin-top: 3%;
    }
    .col-section-1 {
        margin-top: 2%;
        max-width: 100%;
    }
    .about-us-text-bg {
        background: #737373;
        padding: 60px 42px;
        margin-top: 0;
        z-index: 1;
        //height: auto;
        height: 500px;
        position: absolute;
        top: -150px;
        max-width: 100%;
    }
    .about-text-col {
        max-width: 100%;
        margin: 0 20px;
    }
    .text-border-break {
        border: 2px solid #FFFFFF;
        border-radius: 1px;
        width: 97px;
        &.bg-black {
            border: 2px solid #333333;
        }
    }

    .about-us-heading {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 64px;
        color: #FFFFFF;
        &.color-black {
            color: #333333;
        }
    }

    .about-us-text {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #FFFFFF;
        &.color-black {
            color: #333333;
        }
    }
    .row-section-2 {
        margin-top: 415px;
        //margin-top: 42%;
        //margin-top: 52vh;
    }

    .who-we-are-bg {
        background: none;
        padding: 60px 42px;
        padding-left: 42px;
        padding-top: 10px;
    }
    
    .what-we-do-text-bg {
        background: #f4f4f4;
        padding: 60px 42px;
        z-index: 1;
    }

    .row-section-3 {
        align-items: unset;
        justify-content: unset;
        flex-flow: column;
        margin-top: 60px;
    }

    .col-section-3 {
        max-width: 100%;
    }
    .nutshell-bg {
        .container-pd {
            padding: 60px 42px;
        }
        .nutshell-f {
            font-size: 18px;
        }
        
    }
    #more {
        display: none;
    }
    #read{
        color: #007bff;
        text-decoration: underline;
        
        &:hover {
        color: inherit;
        text-decoration: underline;
        color: #40a9ff;
        
    }}
}

@media (max-width: 767px) {
    .about-us-text-bg {
        height: 500px;
    }
    
}

@media (max-width: 690px) {
    .about-us-text-bg {
        height: 590px;
    }
    .row-section-2 {
        margin-top: 502px;
    }
    
}

@media (max-width: 575px) {
    .about-us-text-bg {
        height: 710px;
    }
    .row-section-2 {
        margin-top: 618px;
    }
}

@media (max-width: 414px) {
    .about-us-text-bg {
        height: 760px;
    }
    .row-section-2 {
        margin-top: 618px;
    }
}

