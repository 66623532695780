.footer-bg {
    background: #2B3240;
    padding: 70px 10% 70px;
}


.app-store-box {
    border: 1px solid white;
}

.footer-grid {
    display: grid !important;
    grid-template-columns: auto auto auto;
    gap: 16px;
}

.flex-imp {
    display: flex !important;
}

.align-end {
    align-items: flex-end;
}