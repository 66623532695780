.heart-icon-container  {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 20px;
    cursor: pointer;
}

.img-fav-icon {
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
    padding: 3px 4px 0px 4px;
    cursor: pointer;
    margin-right: 8px;
    z-index: 1;
    border-radius: 4px;
    align-content: center;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }