.ai-background-container {
    margin-top: 81px;
    background-image: url('/ai_creator/Banner.png');
    background-size: cover;
    background-position: center;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    padding: 5% 4% 0 4%;
    flex-wrap: nowrap;

    @media (max-width: 990px) {
        margin-top: 58px;
        padding: 5% 0% 0 0%;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

.ai-overlay-content {
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.ai-content-container {
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.white-bg {
    text-align: center;
    background-color: white;
    padding: 6px;
    border-radius: 4px;
    width: fit-content;

    &.drop-shadow {
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }

    &.pd-20 {
        padding: 20px;
    }

}

.offer-img {
    width: 180px;
    height: 120px;
    gap: 0px;
    object-fit: contain;
    border-radius: 4px 0px 0px 0px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-10 {
    margin-bottom: 10px;
}

.model-image {
    display: block;
    width: 100%;
    max-height: 600px;
    object-fit: contain;
    margin-top: -120px;
}

.max-w-fit-content {
    max-width: fit-content !important;
    @media (min-width: 663px) and (max-width: 990px) {
        max-width: 100%;
        width: 100%;
    }
}

.ant-carousel {
    .ai-carousel {
        .slick-list .slick-slide.slick-active {
            display: flex !important;
            justify-content: center !important;
        }
    }
}


.signup-modal {
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-title {
        margin-top: 20px;
    }

    .ant-modal-header {
        padding: 16px;
        border-bottom: none !important;
    }

    .ant-modal-body {
        padding: 16px;
    }

    @media (max-width: 990px) {
        width: 100%;
        top: 0px;
        margin: 0px;
    }
}


.pd-16 {
    padding: 16px;
}

.signup-select {
    .ant-select-selection-search {
        display: flex !important;
        font-family: Manrope;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
        align-self: center;
        font-family: Manrope;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100% !important;
        font-family: Manrope;
    }
}

.mr-6 {
    margin-right: 6px;
}

#signup-form {
    font-family: Manrope;

    .ant-form-item-explain.ant-form-item-explain-error {
        text-align: left;
    }
}

.mt-10 {
    margin-top: 10px;
}


.upload-btn-bar {
    position: fixed;
    right: 0px;
    left: 0px;
    padding: 20px 40px;
    border-radius: 4px;
    background: white;
    box-shadow: 0px 17px 8px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: flex-end;
    bottom: -100px;
    transition: transform 0.5s ease-in-out;
    transform: translateY(100%);

    &.show {
        bottom: 0px;
        transform: translateY(0);
    }
}

.mb-30 {
    margin-bottom: 30px;
}

.align-flex-start {
    align-items: flex-start !important;
}

.pd-10 {
    padding: 0px 10% !important;
}

.pd-b-40 {
    padding-bottom: 40px !important;
}

.mb-w-100 {
    @media (min-width: 663px) and (max-width: 990px) {
        max-width: 100%;
        width: 100%;
    }
}

.mr-8 {
    margin-right: 8px;
}

.pd-40-56 {
    padding: 40px 56px;
}